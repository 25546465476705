<template>
  <b-card>
    <h4 class="text-black">
      <strong>Pengaturan PIN</strong>
    </h4>

    <b-row class="mt-3 align-items-center justify-between">
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center ml-1 mb-1"
      >

        <b-row class="d-flex align-items-center">
          <b-img
            variant="light-primary"
            square
            class="mr-1"
            src="@core/assets/image/icon-on-page-pin.png"
          />
          <div class="mt-50 pr-1">
            <h4>
              <strong>PIN</strong>
            </h4>
            <small>
              <strong>Pastikan PIN kamu aman karena digunakan untuk menarik saldo dan mengatur rekening bank</strong>
            </small>
          </div>
        </b-row>
      </b-col>
      <b-col
        v-if="!waitingCheckPIN"
        cols="12"
        md="auto"
      >
        <b-row class="mr-1">
          <b-button
            variant="primary"
            @click="checkVerifyPIN()"
          >
            Ganti PIN
          </b-button>
          <b-button
            variant="outline-primary"
            class="ml-1"
            @click="$bvModal.show('req-OTP-email')"
          >
            Lupa PIN
          </b-button>
        </b-row>
      </b-col>
      <b-col
        v-else
        cols="12"
        md="auto"
      >
        <b-row class="mr-1">
          <b-button
            variant="primary"
            :disabled="checkPinError"
            @click="$bvModal.show('create-pin')"
          >
            Buat PIN
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <ModalReqOTP
      id="req-OTP-email"
      title="Lupa PIN"
      :email="profile.user_email"
      :partner-id="profile.partner_id"
      purpose="pin"
      @on-confirm-button="$bvModal.show('confirm-otp-email')"
    />

    <ModalVerifyOtp
      id="confirm-otp-email"
      title="Lupa PIN"
      :email="profile.user_email"
      :partner-id="profile.partner_id"
      request-type="pin"
      purpose="pin"
      label-confirm-button="Konfirmasi"
      @on-confirm-button="successVerifyOtp"
    />

    <ModalPIN
      id="modal-verify-pin"
      :partner-id="profile.partner_id"
      title="Masukan PIN Lama"
      label-confirm-button="Konfirmasi"
      @on-confirm-button="successVerifyPIN"
    />

    <ModalMaxChancePIN />

    <ModalPIN
      id="create-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Buat PIN"
      description="Amankan saldo akunmu menggunakan PIN"
      label-confirm-button="Kirim"
      label-cancel-button="Batal"
      @on-confirm-button="createPIN"
    />

    <ModalPIN
      id="confirm-create-pin"
      :partner-id="profile.partner_id"
      :previous-pin="dataPin"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Konfirmasi PIN"
      description="Masukan Ulang PIN"
      label-confirm-button="Kirim"
      label-cancel-button="Kembali"
      @on-confirm-button="$bvModal.show('modal-success-create-pin')"
      @on-cancel-button="$bvModal.show('create-pin')"
    />

    <ModalNonePIN
      id="modal-success-create-pin"
      icon="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
      title="Yeay! PIN Berhasil Dibuat!"
      label-confirm-button="Tutup"
      @on-confirm-button="$bvModal.hide('modal-success-create-pin'), showModal()"
    />

    <ModalPIN
      id="change-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Masukkan PIN Baru"
      label-confirm-button="Ganti PIN"
      @on-confirm-button="changePIN"
    />

    <ModalPIN
      id="confirm-change-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :previous-pin="dataPin"
      :old-pin="oldPin"
      title="Masukkan Ulang PIN Baru"
      label-confirm-button="Ganti PIN"
      @on-confirm-button="$bvModal.show('modal-success-change-pin')"
    />

    <ModalNonePIN
      id="modal-success-change-pin"
      icon="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
      title="PIN Berhasil Diganti"
      label-confirm-button="Tutup"
      @on-confirm-button="$bvModal.hide('modal-success-change-pin')"
    />

    <ModalPIN
      id="forgot-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Masukkan PIN Baru"
      label-confirm-button="Ganti PIN"
      @on-confirm-button="forgotPIN"
    />

    <ModalPIN
      id="confirm-forgot-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :previous-pin="dataPin"
      :old-pin="oldPin"
      :security-pin="securityChange"
      title="Masukkan Ulang PIN"
      label-confirm-button="Ganti PIN"
      @on-confirm-button="$bvModal.show('modal-success-change-pin')"
    />

  </b-card>
</template>

<script>
import { removeLocalStorageLogout } from '@/auth/utils'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BImg,
  VBModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ModalPIN from '@/views/components/modal/ModalPIN.vue'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'
import ModalNonePIN from '@/views/components/modal/ModalNonePIN.vue'
import ModalReqOTP from '@/views/components/modal/ModalReqOTP.vue'
import ModalVerifyOtp from '@/views/components/modal/ModalVerifyOTP.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    ModalPIN,
    ModalMaxChancePIN,
    ModalNonePIN,
    ModalReqOTP,
    ModalVerifyOtp,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loadingSubmit: false,
      checkPinError: true,
      dataPin: '',
      countOtp: 0,
      oldPin: '',

      messageErrorOTP: '',
      securityChange: null,
      lastChanceOTP: false,
      maxChanceOTP: false,
      disabledInputOTP: false,
      removeLocalStorageLogout,
      waitingCheckPIN: true,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.showModal()
  },
  methods: {
    successVerifyPIN(value) {
      this.oldPin = value
      this.$bvModal.show('change-pin')
    },
    createPIN(value) {
      this.dataPin = value
      this.$bvModal.show('confirm-create-pin')
    },
    changePIN(value) {
      this.dataPin = value
      this.$bvModal.show('confirm-change-pin')
    },
    forgotPIN(value) {
      this.dataPin = value
      this.$bvModal.show('confirm-forgot-pin')
    },
    successVerifyOtp(value) {
      this.securityChange = value
      this.$bvModal.show('forgot-pin')
    },
    checkVerifyPIN() {
      this.$http_new.get('/auth/api/v1/user/secured/verify-pin/attempt-left')
        .then(response => {
          const { data } = response.data
          if (data.attempt_left === 0) {
            this.$bvModal.show('max-chance-PIN')
          } else {
            this.$bvModal.show('modal-verify-pin')
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal cek PIN, silahkan refresh page' })
        })
    },
    showModal() {
      this.$http_komship.get('/v1/pin/check')
        .then(response => {
          const { data } = response.data
          this.checkPinError = false
          if (data.is_set) {
            this.$bvModal.hide('create-pin')
            this.waitingCheckPIN = false
          } else {
            this.$bvModal.show('create-pin')
          }
        }).catch(() => {
          this.checkPinError = true
          this.$toast_error({ message: 'Gagal cek pin, silahkan refresh page' })
        })
    },
  },
}
</script>

<style>
  [dir] .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  [dir] .otp-input::-webkit-inner-spin-button,
  [dir] .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
<style lang="scss" src="./Profile/Profile.scss" />
